<template>
  <chart :options="options" :height="chartHeight" :on="events" />
</template>

<script>
import Chart from '@/components/echarts'

export default {
  name: 'EnChart',
  components: { Chart },
  props: {
    chartData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      chartHeight: '',
      options: {}
    }
  },
  computed: {
    events() {
      return [
        {
          name: 'legendselectchanged',
          method: () => {
            this.options.legend.selected.team = !this.options.legend.selected.team
            this.options.legend.selected.people = !this.options.legend.selected.people
          }
        }
      ]
    }
  },
  watch: {
    chartData: {
      handler(data) {
        this.chartHeight = (data.time.length * 30).toString() + 'px'

        this.options = {
          title: {
            text: ''
          },
          legend: {
            data: ['team', 'people'],
            selected: {
              'team': false,
              'people': true
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          toolbox: {
            feature: {
              saveAsImage: {}
            }
          },
          xAxis: [
            {
              type: 'value'
            }
          ],
          yAxis: {
            type: 'category',
            axisTick: {
              show: true
            },
            data: data.time
          },
          series: [
            {
              name: 'team',
              type: 'bar',
              stack: '总量',
              label: {
                show: true
              },
              data: data.team
            },
            {
              name: 'people',
              type: 'bar',
              stack: '总量',
              label: {
                show: true
              },
              data: data.people
            }
          ]
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
