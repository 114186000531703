<template>
  <div :id="id" :class="className" :style="{ height: height, width: width }" />
</template>

<script>
import custom from './custom.json'

export default {
  name: 'Chart',
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    },
    options: {
      type: Object,
      default: () => ({})
    },
    on: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      chart: null
    }
  },
  watch: {
    options: {
      handler(options) {
        // 设置true清空ECharts缓存
        this.chart.setOption(options, true)
      },
      deep: true
    },
    height: {
      handler(height) {
        this.chart.resize({ height: height })
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  methods: {
    initChart() {
      // 初始化ECharts
      this.chart = this.$echarts.init(this.$el, custom)
      this.chart.setOption(this.options)
      this.on.forEach(event => {
        this.chart.on(event.name, event.method)
      })
    }
  }
}
</script>

<style scoped>

</style>
