<template>
  <div class="bg-light">
    <div class="text-center px-3 py-3 shadow-sm title">
      超能国际生—“扬帆起杭”第二届全球跨境电商创业创新大赛实时报名数据图
    </div>
    <b-container class="mt-5" fluid="true">
      <b-row class="mb-3 mx-lg-5" align-h="between">
        <b-col lg="1" cols="12" class="mb-5">
          <b-row class="bg-white px-1 py-3 shadow-sm text-center" align-h="center">
            <b-col class="mb-2">
              <font-awesome-icon icon="users" size="2x" style="color: #0099CC" />
              <span class="d-lg-none card-panel-description ml-3">报名总人数</span>
            </b-col>
            <b-col>
              <count-to :start-val="0" :end-val="total" :duration="2600" class="card-panel-num text-silver" />
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="5" class="bg-white py-3 px-5 shadow-sm mr-lg-3 mb-3">
          <h4 class="text-center mb-5">直播赛道柱状统计图</h4>
          <live-chart :chart-data="liveData" />
        </b-col>
        <b-col lg="5" class="bg-white py-3 px-5 shadow-sm">
          <h4 class="text-center mb-5">创业赛道柱状统计图</h4>
          <en-chart :chart-data="enData" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { getEnrollment } from '@/api/statistics'
import LiveChart from '@/views/statistics/components/LiveChart'
import EnChart from '@/views/statistics/components/EnChart'
import CountTo from 'vue-count-to'
import '@/assets/css/base.css'

export default {
  name: 'Index',
  components: { LiveChart, EnChart, CountTo },
  data() {
    return {
      liveData: {},
      enData: {},
      beginTime: '2021-01-11',
      total: 0
    }
  },
  created() {
    this.getEachDayData()
  },
  methods: {
    getEachDayData() {
      const params = { 'beginTime': this.beginTime }
      getEnrollment(params).then(e => {
        this.liveData = e.data.l
        this.enData = e.data.e

        const liveLen = e.data.l.time.length
        const enLen = e.data.e.time.length

        this.total = e.data.l.total[liveLen - 1] + e.data.e.people[enLen - 1]
      })
    }
  }
}
</script>

<style scoped>
.title {
  font-size: 1.2rem;
}
.card-panel-num {
  font-size: 1.8rem;
}
.card-panel-description {
  font-size: 1.2rem;
}
</style>
